import React, { useState } from "react";
// import Slider from "react-slick";
import { Outlet, useLocation } from "react-router-dom";

import { routes } from "../PageRoutes.js";

import logo from "../images/guidanceplusni.png";
import banner1 from "../extra-images/banner-1.jpg";
import bannerThumb1 from "../extra-images/banner-thumb-1.png";
// import bannerThumb2 from "../extra-images/banner-thumb-2.png";
import footerLogo from "../images/guidanceplusni.png";

import newslatter from "../extra-images/news-latter-img.jpg";
import { COURSE_DETAILS } from "./CourseDetailsData";
import { COUNTRY_DETAILS } from "./CountryDetailsData";

function shuffleArray() {
  let newArr = [...COURSE_DETAILS];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
}

const Layout = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const pageRouteFound = routes.find((r) => `/${r.path}` === location.pathname);
  const [showHideNav1, setShowHideNav1] = useState(false);
  const [showHideNav2, setShowHideNav2] = useState(false);

  const shuffledCourses = shuffleArray();
  const selectedCourses = shuffledCourses.slice(0, 6);
  const firstCourses = selectedCourses.slice(0, 3);
  const secondCourses = selectedCourses.slice(3, 6);

  return (
    <>
      <div className="education-main-wrapper">
        <header id="education-header" className="education-header-one">
          <div className="education-top-strip education-bgcolor">
            <div className="container">
              <div className="row">
                <aside className="col-md-6">
                  <ul className="education-userinfo">
                    <li>
                      <a href="tel:+447507272479">
                        <i className="fa fa-phone"></i> +(44) 7507272479
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@guidanceplusni.com">
                        <i className="fa fa-envelope"></i> info@guidanceplusni.com
                      </a>
                    </li>
                  </ul>
                </aside>
                <aside className="col-md-6">
                  <ul className="education-user-section">
                    <li>
                      <a href="/book-an-appointment" className="appointment-btn">
                        <i class="fa fa-calendar"></i> Book An Appointment
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61558535126017"
                        target="_blank"
                      >
                        <i class="fa fa-facebook-square"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.instagram.com/guidanceplusni?igsh=NXB2Y3R5cjB4ZWpu"
                        target="_blank"
                      >
                        <i class="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div className="education-main-header">
            <div className="container-fluid">
              <div className="row">
                {/* <div className="col-md-12">
                  <div class="scrolling-message">Website Under Construction</div>
                </div> */}
                <aside className="col-md-4">
                  <nav className="navbar navbar-default">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-1"
                        aria-expanded="true"
                        onClick={() => setShowHideNav1(!showHideNav1)}
                      >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      className={`collapse navbar-collapse ${showHideNav1 ? "in" : ""}`}
                      id="navbar-collapse-1"
                    >
                      <ul className="nav navbar-nav">
                        <li className={isHome ? "active" : ""}>
                          <a href="/">Home</a>
                        </li>
                        <li className={pageRouteFound?.path === "about-us" ? "active" : ""}>
                          <a href="/about-us">About us</a>
                        </li>
                        <li
                          className={
                            pageRouteFound?.path === "study-abroad-courses" ? "active" : ""
                          }
                        >
                          <a href="/study-abroad-courses">Courses</a>
                        </li>
                        <li>
                          <a href="#">Country</a>
                          <ul className="education-dropdown-menu">
                            {COUNTRY_DETAILS.map((country) => (
                              <li>
                                <a href={country?.link}>{country?.name}</a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </aside>
                <aside className="col-md-3 no-pad logo-outer">
                  <a href="/" className="education-logo">
                    <img src={logo} alt="Guiguidanceplusni" />
                  </a>
                </aside>
                <aside className="col-md-4">
                  <nav className="navbar navbar-default">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-2"
                        aria-expanded="true"
                        onClick={() => setShowHideNav2(!showHideNav2)}
                      >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      className={`collapse navbar-collapse ${showHideNav2 ? "in" : ""}`}
                      id="navbar-collapse-2"
                    >
                      <ul className="nav navbar-nav">
                        <li>
                          <a href="#">Services</a>
                          <ul className="education-dropdown-menu">
                            <li>
                              <a href="/">World wide university admission</a>
                            </li>
                            <li>
                              <a href="/">Skilled worker placement</a>
                              <ul className="education-dropdown-menu">
                                <li>
                                  <a href="/">Employer</a>
                                </li>
                                <li>
                                  <a href="/">Jobseeker</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/">Visa Application Assistance</a>
                            </li>
                            <li>
                              <a href="/">Overseas NMC Registration Assistance</a>
                            </li>
                            <li>
                              <a href="/services/oci-application-assistance">
                                OCI Application Assistance
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/recruitment">Recruitment</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </aside>
              </div>
            </div>
          </div>
        </header>

        {isHome ? (
          <div className="education-banner">
            <div className="education-banner-one">
              <div className="education-banner-one-layer">
                <img src={banner1} alt="Guiguidanceplusni" />
                <div className="education-banner-caption">
                  <span className="education-bgcolor">REALIZE YOUR TRUE POTENTIAL WITH THE</span>
                  <br />
                  <span className="education-bgcolor banner-title">
                    Best Study Abroad Consultant
                  </span>
                </div>
                <div className="education-banner-thumb">
                  <img src={bannerThumb1} alt="Guiguidanceplusni" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          pageRouteFound && (
            <div className="education-subheader">
              <span className="subheader-transparent"></span>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="education-subheader-title">
                      <h1>{pageRouteFound?.element?.props?.pageTitle ?? ""}</h1>
                    </div>
                    <ul className="education-breadcrumb">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <i className="fa fa-long-arrow-right"></i>
                      </li>
                      <li className="active">{pageRouteFound?.element?.props?.pageTitle ?? ""}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {isHome || pageRouteFound ? (
          <div className="education-main-content">
            <Outlet />

            <div className="education-main-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="education-subscribe-newsletter">
                      <img src={newslatter} alt="Guiguidanceplusni" />
                      <div className="education-newslatter-text">
                        <h2>Subscribe To Our Newsletter</h2>
                        <form>
                          <input
                            type="text"
                            value="Type Here"
                            onblur="if(this.value == '') { this.value ='Type Here'; }"
                            onfocus="if(this.value =='Type Here') { this.value = ''; }"
                          />
                          <label>
                            <input type="submit" value="Subscribe Now" />
                          </label>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Outlet />
        )}

        <footer id="education-footer" className="education-footer-one">
          <span className="education-footer-pattren"></span>

          <div className="education-footer-widget">
            <div className="container">
              <div className="row">
                <aside className="col-md-3 widget widget_contact_info">
                  <a href="/" className="education-footer-logo">
                    <img src={footerLogo} alt="Guiguidanceplusni" />
                  </a>
                  <ul>
                    <li>
                      <i className="education-color fa fa-home"></i> 115 Cregagh Road Belfast BT6
                      0LA 028 9013 6332
                    </li>
                    <li>
                      <i className="education-color fa fa-phone"></i>
                      <a href="tel:+447922299856">+(44) 7922299856</a>
                    </li>

                    <li>
                      <i className="education-color fa fa-phone"></i>
                      <a href="tel:02890136332">028 9013 6332</a>
                    </li>

                    <li>
                      <i className="education-color fa fa-mobile"></i>
                      <a href="tel:+447507272479">+44 7507 272479</a>
                    </li>

                    <li>
                      <i className="education-color fa fa-envelope"></i>
                      <a href="mailto:info@guidanceplusni.com">info@guidanceplusni.com </a>
                    </li>
                  </ul>
                </aside>

                <aside className="col-md-3 widget widget_contact_info">
                  <div className="education-footer-title">
                    <h4>Opening Hours</h4>
                  </div>
                  <ul>
                    <li>
                      <i className="education-color fa fa-clock-o"></i> Weekdays <br />
                      Monday to Friday
                      <br /> 9:15 AM to 5:30 PM
                    </li>

                    <li>
                      <i className="education-color fa fa-clock-o"></i> Weekends <br />
                      Saturday
                      <br /> 10:30 AM to 2:00 PM
                    </li>

                    <li>
                      <i className="education-color fa fa-clock-o"></i> Sunday - Holiday
                    </li>
                  </ul>
                </aside>

                <aside className="col-md-3 widget widget_featured_courses">
                  <div className="education-footer-title">
                    <h4>Featured Courses</h4>
                  </div>
                  <ul>
                    {firstCourses.map((course) => (
                      <li>
                        <figure>
                          <a href={course?.link}>
                            <img src={course?.image} alt={course?.pageTitle} />
                            <i class="fa fa-link"></i>
                          </a>
                          <figcaption>
                            <h6>
                              <a href={course?.link}>{course?.pageTitle}</a>
                            </h6>
                          </figcaption>
                        </figure>
                      </li>
                    ))}
                  </ul>
                </aside>

                <aside className="col-md-3 widget widget_featured_courses">
                  <div className="education-footer-title">
                    <h4>Featured Courses</h4>
                  </div>
                  <ul>
                    {secondCourses.map((course) => (
                      <li>
                        <figure>
                          <a href={course?.link}>
                            <img src={course?.image} alt={course?.pageTitle} />
                            <i class="fa fa-link"></i>
                          </a>
                          <figcaption>
                            <h6>
                              <a href={course?.link}>{course?.pageTitle}</a>
                            </h6>
                          </figcaption>
                        </figure>
                      </li>
                    ))}
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="education-copyright">
                  <p>
                    All Right Reserved 2024 - by <a href="/">GuidanceplusNI</a>
                  </p>
                  <ul>
                    <li>
                      <a href="/">Privacy</a>
                    </li>
                    <li>
                      <a href="/">Terms</a>
                    </li>
                    <li>
                      <a href="/">Sitemap</a>
                    </li>
                  </ul>
                  <a href="#" className="education-back-top">
                    <i className="fa fa-angle-up"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="clearfix"></div>
      </div>
    </>
  );
};

export default Layout;
